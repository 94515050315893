import React from "react"
import { css } from "@emotion/core"
import Slideshow from "../components/Slideshow"
import useScroll from "../utils/useScroll"
import Section from "../components/Section"
import { get_global_scroller } from "../utils/scrollHandler"
import { FluidObject } from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

type UrlProps = {
  allFile: {
    edges: {
      node: {
        base: string
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }[]
  }
}

export const Media = () => {
  const [ref] = useScroll(get_global_scroller())
  const id = "media"

  const {
    allFile: { edges },
  } = useStaticQuery<UrlProps>(graphql`
    query {
      allFile(
        sort: { fields: [base], order: ASC }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "presskit" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section
      ref={ref}
      id={id}
      css={css`
        background: #1a262d;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            height: 40em;
            width: 100%;
            margin-top: 6em;
            position: relative;
            background: #1a262d;
          `}
        >
          <Slideshow edges={edges} backgroundColor="#1a262d"/>
        </div>
      </div>
    </Section>
  )
}

export default Media
