import React, { MutableRefObject } from "react"
import { css } from "@emotion/core"

export interface SectionProps extends React.AllHTMLAttributes<HTMLDivElement> {}

export default React.forwardRef(
  ({ children, ...props }: SectionProps, ref: MutableRefObject<unknown>) => (
    <section
      ref={ref}
      css={css`
        width: 100%;
        min-height: 100vh;
        text-align: center;
        position: relative;
        display:flex;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
      `}
      {...props}
    >

        {children}
    </section>
  )
)
