import React from "react"
import { css } from "@emotion/core"
import ResponsiveIframe from "../components/ResponsiveIframe"
import useScroll from "../utils/useScroll"
import Section from "../components/Section"
import { get_global_scroller } from "../utils/scrollHandler"
import { before_video } from "./texts";

const YOUTUBE_ID = "oufdFenPFpk"

export const TheGame = () => {
  const [ref] = useScroll(get_global_scroller())
  const id = "game"

  return (
    <Section
      ref={ref}
      id={id}
      css={css`
        background-image: linear-gradient(to bottom, #4b202e, #1a262d 70%);
        padding: 15em;
        @media (max-width: 1224px) {
          padding: 6em;
        }
        @media (max-width: 768px) {
          padding: 2em;
        }
      `}
    >
      <h1
        css={css`
          color: #bcf2ed;
          max-width: 26em;
          margin: 2em auto;
        `}
      >
        {before_video}
      </h1>
      <ResponsiveIframe src={`https://www.youtube-nocookie.com/embed/${YOUTUBE_ID}`} />
    </Section>
  )
}

export default TheGame
