import { onScroll } from "./scrollHandler"
import { useEffect, useRef, useState } from "react"
import { setScrollCurrent } from "./globalManager";

export type AnyHTMLElement =
  | HTMLDivElement
  | HTMLAnchorElement
  | HTMLElement
  | HTMLFieldSetElement
  | HTMLFormElement
  | HTMLHeadElement

export type UseScrollResult = [
  React.MutableRefObject<AnyHTMLElement>,
  React.MutableRefObject<EventTarget>
]

const isInView = (section: HTMLElement, scroll: number, margin = 200) => {
  return (section.offsetTop + margin <= scroll) && (section.offsetTop + section.offsetHeight + margin > scroll)
}

export const useScroll = (target?: EventTarget): UseScrollResult => {
  const refContainer = useRef<EventTarget>(target)
  const refChild = useRef<HTMLElement>(null)
  const [targets, setTargets] = useState(new Set<HTMLElement>())

  const whenScroll = (scroll: number) => {
    for(const target of targets){
      if(isInView(target, scroll)){
        return setScrollCurrent(target.id || "")
      }
    }
    setScrollCurrent("")
  }

  useEffect(() => {
    if (!refContainer.current) {
      return
    }

    const { addListener } = onScroll(refContainer.current);

    return addListener(whenScroll)

  }, [refContainer, targets])

  useEffect(() => {
    if (!refChild.current) {
      return
    }
    const target = refChild.current

    if(targets.has(target)){ return }
    setTargets(new Set([...targets, target]))

    return () => {
      if(!targets.has(target)){ return }
      const _targets = new Set(targets)
      _targets.delete(target)
      setTargets(_targets)
    }

  }, [refChild])

  return [ refChild, refContainer ]
}

export default useScroll
