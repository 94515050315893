import React, { useState } from "react"
import { css } from "@emotion/core"
import { lighten, transparentize } from "polished"
import Img, { FluidObject } from "gatsby-image"
import Modal from "./Modal"

interface Props {
  backgroundColor?: string
  edges: {
    node: {
      base: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }[]
}

const nameToAlt = (name: string) => {
  return name
    .replace(/^\d+/, "")
    .replace(/^[-_]+/, "")
    .replace(/\.\w+$/, "")
    .replace(/_|-/g, " ")
}

const distance = 120
const rotation = 5
const zoom_selected = 150
const zoom_normal = 500

export default ({ edges, backgroundColor }: Props) => {
  const images = edges.map(
    ({
      node: {
        base,
        childImageSharp: { fluid },
      },
    }) => ({ ...fluid, name: base })
  )
  const [selected, setSelected] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false)
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)

  const next = () =>
    setSelected(selected === images.length - 1 ? 0 : selected + 1)
  const prev = () =>
    setSelected(selected === 0 ? images.length - 1 : selected - 1)

  const currentImage = images[selected]

  const button = css`
    position: absolute;
    z-index: 3;
    display: block;
    height: 100%;
    cursor: pointer;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #25867e;
    font-size: 6em;
    &:hover {
      color: ${lighten(0.3, `#25867e`)};
    }
  `

  const bgColor = backgroundColor ? backgroundColor : "inherit"
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        max-width: 900px;
        margin: 0 auto;
        background: ${bgColor};
        overflow: hidden;
        position: relative;
        perspective: 300px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 20%;
          height: 100%;
          display: block;
          z-index: 2;
        }
        ${backgroundColor
          ? css`
              &::before {
                left: 0;
                background-image: linear-gradient(
                  to right,
                  ${bgColor} 10%,
                  ${transparentize(1, bgColor)}
                );
              }
              &::after {
                right: 0;
                background-image: linear-gradient(
                  to left,
                  ${bgColor} 10%,
                  ${transparentize(1, bgColor)}
                );
              }
            `
          : ""}
      `}
    >
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image"
      >
        {currentImage && (
          <Img
            key={currentImage.name}
            fluid={currentImage}
            css={css`
              width: 100%;
              height: auto;
              box-sizing: border-box;
              display: inline-block;
            `}
            alt={currentImage.name.replace(/\.\w\w\w$/, "")}
          />
        )}
      </Modal>
      {images.map((fluid, index) => (
        <span css={css`cursor: pointer;`} key={fluid.name} onClick={openModal}>
          <Img
            title={fluid.name}
            fluid={fluid}
            alt={nameToAlt(fluid.name)}
            style={{
              opacity: index === selected ? 1 : backgroundColor ? 1 : 0.2,
              position: `absolute`,
              zIndex: selected === index ? 1 : -1,
              transform: `translateY(-50%) translateX(${
                index * distance - selected * distance
              }%) translateZ(-${
                index === selected ? zoom_selected : zoom_normal
              }px) rotateY(${index * rotation - selected * rotation}deg)`,
            }}
            css={css`
              height: auto;
              width: 100%;
              top: 50%;
              transition: all 1s ease-out;
              transform: translateY(-50%) translateX(0) translateZ(300px)
                scale(0.2);
              z-index: 0;
            `}
          />
        </span>
      ))}
      <a
        title="previous"
        css={css`
          ${button};
          left: 0;
        `}
        onClick={prev}
      >
        ❮
      </a>
      <a
        title="next"
        css={css`
          ${button};
          right: 0;
        `}
        onClick={next}
      >
        ❯
      </a>
    </div>
  )
}
