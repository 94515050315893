const cache = new Map()

interface ScrollListener {
  (scroll: number): void
}

export const get_global_scroller = () =>
  typeof window !== "undefined" ? window : null

const _createScrollListener = (element: EventTarget) => {
  let latestKnownScrollY = 0
  let ticking = false

  const listeners = new Set<ScrollListener>()

  const update = () => {
    ticking = false
    const scroll = window.scrollY
    listeners.forEach(listener => listener(scroll))
  }

  const requestTick = () => {
    if (!ticking) {
      requestAnimationFrame(update)
    }
    ticking = true
  }

  const onScroll = () => {
    latestKnownScrollY = window.scrollY
    requestTick()
  }

  const addListener = (listener: ScrollListener) => {
    listeners.add(listener)
    return () => listeners.delete(listener)
  }

  const clean = () => {
    listeners.clear()
    element.removeEventListener("scroll", onScroll)
  }

  element.addEventListener("scroll", onScroll, false)

  return { addListener, clean }
}

export const onScroll = (
  element: EventTarget
): ReturnType<typeof _createScrollListener> => {
  if (!cache.has(element)) {
    const listener = _createScrollListener(element)
    cache.set(element, listener)
    return listener
  }
  return cache.get(element)
}

export default onScroll
