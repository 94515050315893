import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SignUp from "../components/SignUp"
import HeaderImage from "../homepage-parts/HeaderImage"
import TheGame from "../homepage-parts/TheGame"
import Demos from "../homepage-parts/Demos"
import Media from "../homepage-parts/Media"
import Community from "../homepage-parts/Community"

// GatsbyImageSharpFluid_tracedSVG
export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
    header: file(relativePath: { eq: "Cover_HD.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <HeaderImage fluid={data.header.childImageSharp.fluid} alt={data.site.siteMetadata.title}/>
      <div
        css={css`
          z-index: 1;
          position: relative;
        `}
      >
        <SignUp />
        <TheGame />
        <Demos />
        <Media />
        <Community backgroundImage={data.background.childImageSharp.fluid} />
        <SignUp invert />
      </div>
    </Layout>
  )
}

export default IndexPage
