import React from "react"
import { css } from "@emotion/core"
import Img, { FluidObject } from "gatsby-image"
import DownArrow from "../components/DownArrow";

export const HeaderImage = ({
  fluid,
  alt,
}: {
  fluid: FluidObject
  alt: string
}) => (
  <div css={css`position:relative;`}>
    <Img
      fluid={fluid}
      css={css`
        max-width: 100%;
        height: auto;
        top: 0;
        z-index: 0;
      `}
      style={{ position: "sticky" }}
      alt={alt}
    />
    <DownArrow css={css`
      position: absolute;
      left:50%;
      bottom: 1.5em;
      transform: translateX(-50%);
    `}/>
  </div>
)

export default HeaderImage
