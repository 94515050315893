import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"


const IframeHolder = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* keeps 16:9 ratio */
  overflow: hidden;
`

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

export default (props:React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>,HTMLIFrameElement>) => 
  <IframeHolder>
    <Iframe   
        allowFullScreen={true}
        width="853"
        height="481"
        frameBorder={0}
        {...props}
    />
  </IframeHolder>