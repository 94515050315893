import React from "react";
import { css } from "@emotion/core";
import { FluidObject } from "gatsby-image";
import { transparentize, lighten } from "polished";
import BackgroundImage from "gatsby-background-image";
import Wrapper from "../components/Wrapper";
import ButtonLink from "../components/ButtonLink";
import { getMenuIcons } from "../components/icons";
import useScroll from "../utils/useScroll";
import Section from "../components/Section";
import { get_global_scroller } from "../utils/scrollHandler";

export const buttonStyle = css`
  font-size: 2em;
  margin: 0.5em;
  height: 2em;
  transition: all 0.5s ease-in;
  &:hover {
    transition: all 0.2s ease-out;
  }
`

export const Community = ({ backgroundImage }: { backgroundImage: FluidObject; }) => {
  const [ref] = useScroll(get_global_scroller());
  const id = "community";

  return (
    <Section
      id={id}
      ref={ref}
      css={css`
        background: #1a262d;
      `}
    >
      <BackgroundImage
        css={css`
          width: 100%;
          height: 100%;
          color: red;
        `}
        fluid={backgroundImage}
        backgroundColor={`#040e18`}
      >
        <Wrapper
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
          `}
        >
          {getMenuIcons("buttons").map(({ Component, name, url, label }) => (
            <ButtonLink
              css={css`
                ${buttonStyle}
                &:hover {
                  color: ${lighten(0.2, "#1bcab9")};
                  box-shadow: 0 2px 25px ${transparentize(0.6, `#ffffff`)};
                }
              `}
              icon={Component}
              to={url}
              iconColor="#bcf2ed"
              color="#1bcab9"
              background="#18262d"
              iconSize={2.5}
              key={name}
            >
              {label}
            </ButtonLink>
          ))}
        </Wrapper>
      </BackgroundImage>
    </Section>
  );
};

export default Community