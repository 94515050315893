import React from "react"
import { css, keyframes } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

const float = keyframes`
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
`

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

type UrlProps = {
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const DownArrow = ({ className }) => {
  const { file } = useStaticQuery<UrlProps>(graphql`
    query {
      file(relativePath: { eq: "arrow.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      <a
        css={css`
          display: block;
          animation: ${bounce} 3s ease infinite;
          box-sizing: border-box;
          height: 5vw;
          width: 5vw;
        `}
        href="#game"
      >
        <Img
          fluid={file.childImageSharp.fluid}
          css={css`
            max-width: 100%;
            height: auto;
          `}
        />
      </a>
    </div>
  )
}

export default DownArrow
