import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const mailChimpURL = `https://playtransmogrify.us16.list-manage.com/subscribe/post?u=6dcbac8f4577e3eaf7741bac0&amp;id=a8d794d096`
const honeypot = `b_6dcbac8f4577e3eaf7741bac0_a8d794d096` // this is found in the embed form, like this:
/**
 * <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
 * <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="<THIS IS THE CODE WE NEED>" tabindex="-1" value="">
 */

const honeyPotInputProps = {
  type: "text",
  name: honeypot,
  tabIndex: -1,
  style: { position: `absolute`, left: -5000 },
  'aria-hidden': true,
}

const MailChimp = styled.div`
  width: 100%;
  background: ${ ({invert}:SignUpProps) => invert ? `#18262d` : `orange` };
  text-align: center;
  height: 20em;
  padding:3em;
  box-sizing: border-box;
  font-weight: 600;
`

export interface SignUpProps{
  invert?:boolean
  id?: string
  text?: string
}

export default ({ invert, id, text = 'Sign up for news and updates'}:SignUpProps) => {
  

  return (
    <MailChimp invert={invert} id={id}>
      <div
        css={css`
          font-size: 1.6em;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 100%;
        `}
      >
        <span
          css={css`
            color: ${ invert ? `orange` : `#32343b` };
            font-size: 1.2em;
            font-family: 'Montserrat', sans-serif;
          `}
        >
          {text}
        </span>
        <form
          action={mailChimpURL}
          method="post"
          name="mc-embedded-subscribe-form"
          target="_blank"
        >
          <input
            type="email"
            name="EMAIL"
            placeholder="email address"
            defaultValue=""
            css={css`
              color: #18262d;
              background: #ffffff;
              &::placeholder {
                color: #565a7a;
              }
            `}
          />
          <input
            type="submit"
            value="Submit"
            css={css`
              color: ${ invert ? `#18262d` :`#2bb1a4`};
              background: ${ invert ? `orange` : `#18262d` };
            `}
          />
          <input {...honeyPotInputProps} />
        </form>
      </div>
    </MailChimp>
  )
}
