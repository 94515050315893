import React from "react"
import { css } from "@emotion/core"
import Wrapper from "../components/Wrapper"
import ButtonLink from "../components/ButtonLink"
import HomePageTheater from "../components/HomePageTheater"
import useScroll from "../utils/useScroll"
import Section from "../components/Section"
import { get_global_scroller } from "../utils/scrollHandler"
import { after_video, after_videos_bullets } from "./texts"

export const Demos = () => {
  const [ref] = useScroll(get_global_scroller())
  const id = "features"

  return (
    <Section
      ref={ref}
      id={id}
      css={css`
        background: #1a262d;
        @media (max-width: 768px) {
          margin-bottom: 200px; /** allows the video to not be hidden by the next item **/
         }
      `}
    >
      <Wrapper>
        <div
          css={css`
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
          `}
        >
          <ButtonLink
            css={css`
              font-size: 2em;
            `}
            className="download-demo"
            icon="demos"
            to="/demos"
            iconColor="#000000"
            color="#32343b"
          >
            Download Demo
          </ButtonLink>
          <h1
            css={css`
              color: #bcf2ed;
              max-width: 26em;
              margin: 2em auto;
            `}
          >
            {after_video}
          </h1>
          <div
            css={css`
            display: flex;
            justify-content: center;
            align-items: center;
              & > ul,
              & > div {
                width: 50%;
              }
              @media (max-width: 768px) {
                flex-wrap: wrap;
                & > ul,
                & > div {
                  width: 100%;
                }
              }
            `}
          >
            <ul
              css={css`
                color: #2bb1a4;
                text-align: left;
                float: left;
                box-sizing: border-box;
                font-size: 1.3em;
                padding-right: 2em;
                & li {
                  margin-top: 1em;
                }
              `}
            >
              {after_videos_bullets.map(text => (
                <li key={text}>{text}</li>
              ))}
            </ul>
            <div
              css={css`
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                @media (max-width: 768px) {
                  padding:3em
                }
              `}
            >
              <HomePageTheater
                sources={[
                  {
                    src: "https://transmogrify-site-downloads.s3.amazonaws.com/video/site_video.mp4",
                    type: "video/mp4",
					          allow: "autoplay"
                  },
                ]}
              ></HomePageTheater>
            </div>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}

export default Demos
