import React from "react"
import { css } from "@emotion/core"
import Theater from "./Theater"

export interface HomePageTheaterProps {
  sources: { src: string; type: string }[]
  className?: string
}

export default ({ sources, className }: HomePageTheaterProps) => (
  <Theater className={className}>
    <video
      controls
      autoPlay
      loop
      muted
      css={css`
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
    >
      {sources &&
        sources.map(({ src, type, ...rest }) => (
          <source src={src} type={type} key={src + type} {...rest} />
        ))}
      Sorry, your browser doesn't support embedded videos.
    </video>
  </Theater>
)
