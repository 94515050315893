import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

type UrlProps = {
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export interface TheaterProps {
  children?: React.ReactNode
  className?: string
}

const style = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default ({ children, className }: TheaterProps) => {
  const {
    file: {
      childImageSharp: { fluid: src },
    },
  } = useStaticQuery<UrlProps>(graphql`
    query {
      file(relativePath: { eq: "frame.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        padding-bottom: 68.189%;
        height: 0;
        margin: 0 auto;
      `}
      className={className}
    >
      <div
        css={css`
          top: 5%;
          left: 11%;
          bottom: 5%;
          right: 11%;
          position: absolute;
          overflow: hidden;
        `}
      >
        {children}
      </div>
      <Img
        fluid={src}
        css={css`
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: auto;
        `}
        style={{ position: `absolute` }}
      />
    </div>
  )
}
